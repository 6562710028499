import React, { useState, useEffect } from 'react';
import "./RequestQuote.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const options = [
 
{ value: 'Blockchain', label: 'Blockchain' },
{ value: 'AI', label: 'AI' },
{ value: 'NFT', label: 'NFT' },
{ value: 'DeFi', label: 'DeFi' },
{ value: 'Meme', label: 'Meme' },
{ value: 'Metaverse', label: 'Metaverse' },
];

const MyComponent: React.FC = () => {
  const [wordCount, setWordCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    // Update the formData when selectedOption changes
    setFormData({ ...formData, services: selectedOption || '' });
  }, [selectedOption]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  const [formData, setFormData] = useState({
    projectname: '',
    ca: '',
    pair: '',
    telegram: '',
    website: '',
    tg: '',
    fb: '',
    exp: '',
    supply: '',
    twitter: '',
    logourl: '',
    services: '', // Remove selectedOption here
    description: '',
  });

  const [statusMessage, setStatusMessage] = useState('');

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = event.target.value;
    setFormData({ ...formData, description });
    const words = description.split(/\s+/).filter(Boolean);
    setWordCount(words.length);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validate form fields (you can add your validation logic here)

    // Assuming you have an API endpoint for form submission
    try {
      const response = await fetch('https://formspree.io/f/xbjngqyq', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        toast.success('Listing Request Submitted Successfully! We will contact you soon...', {
          className: 'toast-dark', // Apply the custom dark theme
        });
  
        setFormData({
          projectname: '',
          ca: '',
          pair: '',
          telegram: '',
          website: '',
          tg: '',
          fb: '',
          exp: '',
          twitter: '',
          supply: '',
          logourl: '',
          services: '',
          description: '',
        });
      } else {
        toast.error('Failed to submit the form. Please try again later.', {
          className: 'toast-dark', // Apply the custom dark theme
        });
      }
    } catch (error) {
      toast.error('An error occurred while submitting the form.', {
        className: 'toast-dark', // Apply the custom dark theme
      });
      console.error(error);
    }
  };

  const redirectToTelegram = () => {
    const telegramLink = "https://t.me/lovep_8bit";
    window.location.href = telegramLink;
  };

  const sendEmail = () => {
    const emailAddress = "admin@8bitchain.io";
    const mailtoLink = `mailto:${encodeURIComponent(emailAddress)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div>
      <ToastContainer />
      <div className='maino'>
         

        <div className="formo">
        <h2>LIST YOUR COIN</h2>
        <div className="para flex-item">
          <p>
           List your crypto asset at <span style={{color:'#6c89ff'}}>PROTRACKERS</span> which exchanges with pool variation in real time
          </p>
        </div>
          <form onSubmit={handleSubmit} className="requestform">
            <div className="formo">
              <div className="formgrid">
                <label>
                  <input
                    type="text"
                    className="inputrequest inputreques"
                    placeholder="Project Name"
                    name="projectname"
                    value={formData.projectname}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label>
                  <input
                    type="text"
                    className="inputrequest inputreques"
                    placeholder="Contract Address"
                    name="ca"
                    value={formData.ca}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <div className="formgrid">
                <label>
                  <input
                    type="text"
                    name="pair"
                    className="inputrequest inputreques"
                    placeholder="Pair Address"
                    value={formData.pair}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="telegram"
                    className="inputrequest inputreques"
                    placeholder="Telegram ID ( Contact )"
                    value={formData.telegram}
                    onChange={handleInputChange}
                    required
                    style={{ marginRight: '40px' }}
                  />
                </label>
              </div>
              <div className="formgrid">
                <label>
                  <input
                    type="text"
                    name="logourl"
                    placeholder="Logo URL"
                    className="inputrequest inputreques"
                    value={formData.logourl}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="supply"
                    placeholder="Circulating Supply"
                    className="inputrequest inputreques"
                    value={formData.supply}
                    onChange={handleInputChange}
                    required
                  />
                </label>
               
              </div>
              <select
                  value={selectedOption || ''}
                  onChange={handleChange}
                  className="selectiondropdownrequest selectiondropdownreques"
                >
                  <option value="" disabled className="optionss">
                    Select Category
                  </option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value} className="optionss">
                      {option.label}
                    </option>
                  ))}
                </select>
              <div className="formgrid">
                <label>
                  <input
                    type="text"
                    name="website"
                    className="inputrequest inputreques"
                    placeholder="Website"
                    value={formData.website}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="twitter"
                    className="inputrequest inputreques"
                    placeholder="Twitter ( Facebook )"
                    value={formData.twitter}
                    onChange={handleInputChange}
                    required
                    style={{ marginRight: '40px' }}
                  />
                </label>
              </div>
              <div className="formgrid">
                <label>
                  <input
                    type="text"
                    name="fb"
                    className="inputrequest inputreques"
                    placeholder="Facebook ( Project )"
                    value={formData.fb}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="tg"
                    className="inputrequest inputreques"
                    placeholder="Telegram ID ( Project )"
                    value={formData.tg}
                    onChange={handleInputChange}
                    required
                    style={{ marginRight: '40px' }}
                  />
                </label>
              </div>
              <label>
                <input
                  type="text"
                  name="exp"
                  className="inputrequest inputreques"
                  placeholder="Explorer Link"
                  value={formData.exp}
                  onChange={handleInputChange}
                  required
                  style={{ marginRight: '40px' }}
                />
              </label>
            </div>
            <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
              <button type="submit" className="custom-button-request custom-button-reques">
                Request Listing
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyComponent;
