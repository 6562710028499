export const ROUTER_ADDRESS = {
  1: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D", // Ethereum Mainnet
  56: "0x10ED43C718714eb63d5aA57B78B54704E256024E", // Binance Smart Chain
};

export const DEFAULT_FROM_TOKEN = {
  1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", // Ethereum Mainnet
  56: "0x111111111117dC0aa78b770fA6A738034120C302", // Binance Smart Chain
};

export const DEFAULT_TO_TOKEN = {
  1: "0xbc6378faae98fb2207bb6c35c0f8ce5846fd4c6c", // Ethereum Mainnet
  56: "0xbc6378faae98fb2207bb6c35c0f8ce5846fd4c6c", // Binance Smart Chain
};

export const WETH_TOKEN = {
  1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", // Ethereum Mainnet
  56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", // WBNB on Binance Smart Chain
};

// Add more configurations or objects as necessary for other chains.


export const DEFAULT_TOKENS = [
  
];

export const WRAPPED_TOKEN = {
  1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
};

export const TO_TOKEN = {
  1: "0x111111111117dC0aa78b770fA6A738034120C302",
  56: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
};

const W8BIT_ADDRESS = {
  56: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
};

export { W8BIT_ADDRESS };
