import React from "react";
import { ReactComponent as IIcon } from "../../assets/icons/i.svg";
import { ReactComponent as Defi } from "../../assets/icons/defi.svg";
import { ReactComponent as Game } from "../../assets/icons/game.svg";
import { ReactComponent as Ai } from "../../assets/icons/ai.svg";
import { ReactComponent as Vr } from "../../assets/icons/vr.svg";
import { ReactComponent as Blockchain } from "../../assets/icons/blockchain.svg";
import { ReactComponent as Meme } from "../../assets/icons/meme.svg";

import LiveDataCard from "../TopPairDataCard";
import LiveDataCard2 from "../AI";
import LiveDataCard3 from "../Blockchain";
import LiveDataCard4 from "../Metaverse";
import LiveDataCard5 from "../Meme";
import LiveDataCard6 from "../DeFI";
import LiveDataCard7 from "../Losers";
import LiveDataCard8 from "../LiveDataCard";
import LiveDataCard9 from "../WallOfShame";


import "./TopPairs.scss";
import { ITokenData } from "../../constants/types";
import {
  AI_BOT_PROJECT,
  BLOCKCHAIN_PROJECT,
  DEFI_PROJECT,
  GAME_NFT_PROJECT,
  MEME_PROJECT,
  METAVERSE,
} from "../../constants/data";

const DextBoard: React.FC<{ data: ITokenData[] }> = ({ data }) => {
  return (
    <div className="toppair-wrapper">
      <div className="dextboard-content flex-item">
      <LiveDataCard6/>
      <LiveDataCard
          // icon={<Defi />}
          // heading="DeFi Projects"
          // iIcon={<IIcon />}
          // liveData={data
          //   .filter((f) => f.title === DEFI_PROJECT)
          //   .map((m) => ({
          //     logo: m.logo,
          //     tokenName: m.tokenName,
          //     status: "profit",
          //     symbol: " / WETH",
          //     dollar: 0.64838899,
          //     arrow: "69,917",
          //   }))}
        />

<LiveDataCard2/>

<LiveDataCard4/>
<LiveDataCard5/>

<LiveDataCard3/>










        {/* <LiveDataCard
          icon={<Defi />}
          heading="DeFi Projects"
          iIcon={<IIcon />}
          liveData={data
            .filter((f) => f.title === DEFI_PROJECT)
            .map((m) => ({
              logo: m.logo,
              tokenName: m.tokenName,
              status: "profit",
              symbol: " / WETH",
              dollar: 0.64838899,
              arrow: "69,917",
            }))}
        />
        <LiveDataCard
          icon={<Game />}
          heading="Game/NFT Projects "
          iIcon={<IIcon />}
          liveData={data
            .filter((f) => f.title === GAME_NFT_PROJECT)
            .map((m) => ({
              logo: m.logo,
              tokenName: m.tokenName,
              status: "profit",
              symbol: " / WETH",
              dollar: 0.64838899,
              arrow: "69,917",
            }))}
        />
        <LiveDataCard
          icon={<Ai />}
          heading="AI/Bots Projects "
          iIcon={<IIcon />}
          liveData={data
            .filter((f) => f.title === AI_BOT_PROJECT)
            .map((m) => ({
              logo: m.logo,
              tokenName: m.tokenName,
              status: "profit",
              symbol: " / WETH",
              dollar: 0.64838899,
              arrow: "69,917",
            }))}
        />
        <LiveDataCard
          icon={<Vr />}
          heading="Metaverse/VR Games "
          iIcon={<IIcon />}
          liveData={data
            .filter((f) => f.title === METAVERSE)
            .map((m) => ({
              logo: m.logo,
              tokenName: m.tokenName,
              status: "profit",
              symbol: " / WETH",
              dollar: 0.64838899,
              arrow: "69,917",
            }))}
        />
        <LiveDataCard
          icon={<Meme />}
          heading="Meme Projects "
          iIcon={<IIcon />}
          liveData={data
            .filter((f) => f.title === MEME_PROJECT)
            .map((m) => ({
              logo: m.logo,
              tokenName: m.tokenName,
              status: "profit",
              symbol: " / WETH",
              dollar: 0.64838899,
              arrow: "69,917",
            }))}
        />
        <LiveDataCard
          icon={<Blockchain />}
          heading="Blockchain Projects "
          iIcon={<IIcon />}
          liveData={data
            .filter((f) => f.title === BLOCKCHAIN_PROJECT)
            .map((m) => ({
              logo: m.logo,
              tokenName: m.tokenName,
              status: "profit",
              symbol: " / WETH",
              dollar: 0.64838899,
              arrow: "69,917",
            }))}
        /> */}
      </div>
    </div>
  );
};

export default DextBoard;
