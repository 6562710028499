import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./page/Home";
import LiveNewPairs from "./components/LiveNewPairs";
import Navigation from "./components/Navigation";
import Scam from "./components/Scam";
import Add from "./components/RequestQuote";

import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import PairExplorer from "./page/PairExplorer";

const App: React.FC = () => {
  return (
    <>
      <Navigation />
      <div className="layout-content">
        <Sidebar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/live-new-pairs" element={<LiveNewPairs />} />
          <Route path="/pair-explorers" element={<PairExplorer />} />
          <Route path="/scam" element={<Scam />} />
          <Route path="/add" element={<Add />} />

        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
