import React, { useEffect, useState } from "react";
import { query, collection, onSnapshot } from "firebase/firestore";
import Hero from "../../components/Hero";
import DextBoard from "../../components/TopPairsi";
import TopPairs from "../../components/TopPairs";
import Slider from "../../components/MarkQuee";

import HotPairs from "../../components/HotPairs";
import PairsList from "../../components/PairsList";
import { ITokenData } from "../../constants/types";
import { db } from "../../utils/firebase";

const Home: React.FC = () => {
  const [data, setData] = useState<ITokenData[]>([]);

  useEffect(() => {
    const q = query(collection(db, "insight"));
    onSnapshot(q, (querySnapshot) => {
      setData(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<ITokenData, "id">),
        }))
      );
    });
  }, []);

  return (
    <div className="pad">
      <Hero />
      {/* <div style={{marginTop:30,marginBottom:30}}>      <Slider/></div> */}

      <DextBoard data={data} />
      <TopPairs data={data} />

      <HotPairs />


      <PairsList />
    </div>
  );
};

export default Home;
