import React, { useState, useEffect } from 'react';
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import "../AI/TopPairDataCard.scss";
import { ILiveData } from "../../constants/types";
import { ReactComponent as Icon } from "../../assets/icons/lowChart.svg"
import { ReactComponent as Searchso } from "../../assets/icons/search.svg"

import { initializeApp } from 'firebase/app';
import axios from "axios";
import { Link } from 'react-router-dom';
import Search from "../Search"
import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';
import millify from "millify";
import { formatDistanceToNow } from 'date-fns';


interface PairData {
  pairAddress: string;

  baseToken: {
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceUsd: number;
  priceNative: number;

  volume: {
    h24: number;
  };
  priceChange: {
    h24: number;
  };

  liquidity:{
    base: number;
    quote:number;
    usd:number;
  }
  pairCreatedAt:any;

  txns:{
    h24:{
      buys:number;
      sells:number;
    }
  }
  // Add other properties based on the actual structure of your data
}

const ProfileImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;
const LiveDataCard:  React.FC = () => {

  
// Firebase Configuration
const firebaseConfig = {
   apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
  authDomain: "protracker-6c490.firebaseapp.com",
  databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
  projectId: "protracker-6c490",
  storageBucket: "protracker-6c490.appspot.com",
  messagingSenderId: "776220672739",
  appId: "1:776220672739:web:d71e515bb8f33673403bd6",
  measurementId: "G-GMYRXFBSRK"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const userDataRef = ref(database, 'projects');

const userDataRefs = ref(database, 'projects');



const [searchTerm, setSearchTerm] = useState('');

const [userData, setUserData] = useState<any[]>([]);
const [userDatas, setUserDatas] = useState<any[]>([]);


const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add this line for loading state
  const [pairData, setPairData] = useState<PairData | null>(null);
  const [pairDataList, setPairDataList] = useState<PairData[]>([]);



useEffect(() => {
  onValue(userDataRef, (snapshot) => {
    const data = snapshot.val();
    const filteredData = data ? Object.values(data) : [];

    if (searchTerm) {
      const searchTermLC = searchTerm.toLowerCase();
      const searchResults = filteredData.filter((user: any) =>
        user.pairAddress.toLowerCase().includes(searchTermLC)
      );
      setUserData(searchResults.reverse());
    } else {
      setUserData(filteredData.reverse());
    }
          setLoading(false); // Data loading is complete

  });
}, [searchTerm]);




const displayedUserData = userData;
const [isFocused, setIsFocused] = useState(false);

const handleFocus = () => {
  setIsFocused(true);
};

const handleBlur = () => {
  setIsFocused(false);
};

const handlePageChange = (newPage: number) => {
  setCurrentPage(newPage);
};

const handlePreviousPage = () => {
  if (currentPage > 1) {
    setCurrentPage(currentPage - 1);
  }
};





useEffect(() => {
  const fetchPairData = async (user: any) => {
    try {
      const pairAddress = user.pairAddress;
      const chain = user.chain;

      const response = await axios.get(`https://openapi.dexview.com/latest/dex/pairs/${chain}/${pairAddress}`);
      const pairData: PairData = response.data.pair;
      setPairDataList((prevPairDataList) => [...prevPairDataList, pairData]);
    } catch (error) {
      console.error("Error fetching pair data:", error);
    }
  };

  const fetchDataSequentially = async () => {
    setPairDataList([]); // Reset pairDataList before fetching new data

    for (const user of userData) {
      await fetchPairData(user);
    }
    setLoading(false);
  };

  fetchDataSequentially();
}, [userData]);

useEffect(() => {
  // Sort pairDataList based on price change in the last 24 hours
  const sortedPairDataList = pairDataList.slice().sort((a, b) => a.priceChange.h24 - b.priceChange.h24);
  setPairDataList(sortedPairDataList);
}, [pairDataList.length, userData.length]);



const formatVolume = (volume:any) => {
  const abbreviations = ['K', 'M', 'B'];

  const format = (num:any, precision:any) => {
    const index = Math.floor(Math.log10(num) / 3);
    return (num / Math.pow(1000, index)).toFixed(precision) + abbreviations[index - 1];
  };

  return format(volume, 2); // You can adjust the precision as needed
};

const [searchTerms, setSearchTerms] = useState<string[]>([]); // State to store matched user IDs


const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  setSearchTerm(value);

  // Filter userData based on pairAddress
  const filteredUserData = userData.filter((user: any) =>
    user.pairAddress.toLowerCase().includes(value.toLowerCase())
  );
  setSearchTerms(filteredUserData.map((user: any) => user.userId)); // Store matched user IDs
};



const noProjectsMessage = (
  <tr>
    <td colSpan={4} style={{ textAlign: 'center',height:'100px' }}>
      No Project Found
    </td>
  </tr>
);

const [searchClicked, setSearchClicked] = useState(false);


const handleSearchClick = (pairAddress: string) => {
  // Set the searchTerm to the pairAddress when search button is clicked
  setSearchTerm(pairAddress);
  setSearchClicked(true);

 
  // Redirect to a new page with the pair address as a query parameter
};


  const handleGoButtonClick = () => {
    window.location.href = `/pair-explorers?id=${encodeURIComponent(userData[0].userId)}`;
  };

// const handleSearchButtonClick = (index: number) => {
//   if (pairDataList[index]) {
//     const pairAddress = pairDataList[index].pairAddress;
//     if (pairAddress) {
//       if (pairAddress.length > 0) {
//         // Call both functions
//         handleSearchClick(pairAddress);
//         handleSearchClicks(pairAddress);
//       }
//     }
//   }
// };
const createtr = (user: any, index: number) => {
  const topGainersCount = 3; // Set the number of top gainers you want to display

  if (pairDataList.length > 0 && index < topGainersCount) {
    return (
      <tr key={user.userId || index} style={{ cursor: 'pointer' }}>
        {pairDataList[index] && (
          <>
            <td>
              <span style={{color:'#ffba00'}}>#{index+1}</span>
            </td>

            {!searchClicked && pairDataList[index] && pairDataList[index].baseToken  && (
       <>
               {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                  <td>
                    {pairDataList[index].baseToken.symbol}/
                    <span style={{ fontSize: 12, color: '#cccccc' }}>
                      {pairDataList[index].quoteToken.symbol}
                    </span>
                  </td>
                )}
         </>
            )}
            {/* Check if pairDataList[index] and its properties are not null before accessing them */}
            {searchClicked && (
              <>
                
                <td><ProfileImage src={user.logoPicture}/></td>
                <td>{user.name}</td>
              </>
            )}
            {/* Additional checks for other properties of pairDataList[index] */}
            {!searchClicked && pairDataList[index] && pairDataList[index].priceUsd != null && (
              <>
               {pairDataList[index] && pairDataList[index].priceUsd != null && (
              <td className="percentso">
                <Link  style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red" }}  to={`/pair-explorers?id=${encodeURIComponent(user.userId)}`}>
                  $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
                </Link>
              </td>
            )}
             {pairDataList[index] && pairDataList[index].priceChange && (
              <td style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red" }}>
  {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              }).format(pairDataList[index].priceChange.h24)}{" "} %              </td>
            )}
            </>
            )}
          
            {/* Modify this <td> to be a clickable link */}
            <td>
              {searchClicked ? (
                <button className='newbteo' onClick={handleGoButtonClick}>Go</button>
              ) : (
                <button className='newbteo' onClick={() => handleSearchClick(pairDataList[index].pairAddress)}><Searchso/></button>
              )}
            </td>
          </>
        )}
      </tr>
    );
  }

  return null;
};


  return (
    <div className="top-data-card-wrapper">
      <div className="border-animation-wrapper">
        <div className="border-animation"></div>
      </div>
      <div className="live-data-card-container">
     

        <div className="live-data-card flex-item">
          <div className="daily-gainers-heading flex-item">
<Icon style={{color:'white',width:20}}/>
            <p>Top Losers</p>
          </div>
          <div className="more-content flex-item"></div>
        </div>
        
<table>
        <tbody>
              { (
                displayedUserData.map((user, index) => createtr(user, index))
                )}
            </tbody>

        

            </table>
            {/* <Search/> */}
        
      </div>
    </div>
  );
};

export default LiveDataCard;
